import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchDataSoccer } from './components/FetchDataSoccer';
import { FetchDataHockey } from './components/FetchDataHockey';
import { FetchDataBasketball } from './components/FetchDataBasketball';
import { LiveKOIs } from './components/LiveKOIs';
import { SoccerTodayHY } from './components/SoccerTodayHY';
import { SoccerTodaySQ } from './components/SoccerTodaySQ'
import { SoccerTodayPP } from './components/SoccerTodayPP'


import { HockeyTodayHH } from './components/HockeyTodayHH';
import { HockeyTodaySQ } from './components/HockeyTodaySQ'
import { HockeyTodayHY } from './components/HockeyTodayHY'
import { BasketballTodayHH } from './components/BasketballTodayHH';
import { BasketballTodaySQ } from './components/BasketballTodaySQ'
import { BasketballTodayHY } from './components/BasketballTodayHY'

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />    
            <Route path='/LiveKOIs' component={LiveKOIs} /> 
            <Route path='/fetch-data-soccer' component={FetchDataSoccer} />
            <Route path='/SoccerTeams' component={SoccerTodaySQ} />
            <Route path='/SoccerTeams' component={SoccerTodayHY} />
            <Route path='/SoccerPlayers' component={SoccerTodayPP} />
            <Route path='/fetch-data-hockey' component={FetchDataHockey} />  
            <Route path='/HockeyH2H' component={HockeyTodayHH} />  
            <Route path='/HockeyTeams' component={HockeyTodaySQ} />           
            <Route path='/HockeyTeams' component={HockeyTodayHY} />
            <Route path='/fetch-data-basketball' component={FetchDataBasketball} />  
            <Route path='/BasketballH2H' component={BasketballTodayHH} />
            <Route path='/BasketballTeams' component={BasketballTodaySQ} />
            <Route path='/BasketballTeams' component={BasketballTodayHY} />
             
    
      </Layout>
    );
  }
}
