import React, { Component } from 'react';
import moment from 'moment';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);

//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'       
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

export class HockeyTodaySQ extends Component {
    static displayName = HockeyTodaySQ.name;

  constructor(props) {
    super(props);
    this.state = { Sequences: [], loading: true };
    }
   
    intervalID;

  componentDidMount() {
      this.populateSequences();     
     
    }
    componentWillUnmount() {       
        clearInterval(this.intervalID);
    }

    static renderSequencesTable(Sequences) {

        var lines = []; 
        var match;        
        lines.push(<tr bgcolor ="burlywood">
            <td width="16%">Home</td>
            <td width="12%">Win threshold from every 8 match sequence</td>
            <td width="12%">Loss threshold  from every 8 match sequence</td>
            <td width="16%">Away</td>
            <td width="12%">Win threshold from every 8 match sequence</td>
            <td width="12%">Loss threshold  from every 8 match sequence</td>
        </tr>)
        for (var i = 0; i < Sequences.length; i++)            
        {
                match = Sequences[i];
            lines.push(<tr bgcolor="white">
                <td class={this.getsequenceclass(match["sequenceWinsH"], match["sequenceLosesH"])}>{match["home"]}</td>
                <td class={this.getsequenceformat(match["sequenceWinsH"])}>{match["sequenceWinsH"]}</td>
                <td class={this.getsequenceformat(match["sequenceLosesH"])}>{match["sequenceLosesH"]}</td>
                <td class={this.getsequenceclass(match["sequenceWinsA"], match["sequenceLosesA"])}>{match["away"]}</td>
                <td class={this.getsequenceformat(match["sequenceWinsA"])}>{match["sequenceWinsA"]}</td>
                <td class={this.getsequenceformat(match["sequenceLosesA"])}>{match["sequenceLosesA"]}</td>
               
            </tr>)
        }
       
      return (
          <div class="tb"> 
              <div class="p7"> Most wins in 8 (occurences) - Most frequent number of wins in 8 (occurences) - Least wins in 8 (occurences) - Wins in last 7</div>
              <div class="p7"> Most losses in 8 (occurences) -Most frequent number of losses in 8 (occurences) - Least losses in 8 (occurences) - losses in last 7</div> 
              <div class="p7" style={this.getStatStyle(3)}>  Recent form is above their seasonal norm </div>
              <div class="p7" style={this.getStatStyle(1)}>  Recent form is below their seasonal norm </div>
              <div class="p7" style={this.getStatStyle(0)}>  Recent form is within the threshold of the seasonal norm for wins and loses </div>
              <p class="p7">
                  <i class="fas fa-hockey-puck"></i>  <a href="\LiveKOIs" ><span>Live feed - here</span> </a><i class="fas fa-hockey-puck"></i><br />
                  <i class="fas fa-hockey-puck"></i> <a href="\fetch-data-hockey" ><span> In play - here</span> </a><i class="fas fa-hockey-puck"></i><br />
                  <i class="fas fa-hockey-puck"></i><a href="\HockeyH2H" ><span> Head2Head - here</span> </a><i class="fas fa-hockey-puck"></i>
              </p>
              <table width="80%">
         <thead>
          <tr>
          <th></th>
          <th></th>         
          </tr>
        </thead>
              {lines}       
              </table>
              <p/><br/>
             
       </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p></p>
        : HockeyTodaySQ.renderSequencesTable(this.state.Sequences);

    return (
        <div>  
            <div class="icon-container">
                <i class="fas fa-hockey-puck" title="hockey"></i>
                <img src="images/us.svg" alt="North America" width="38" height="20" />
                <img src="images/eu.svg" alt="Europe" width="38" height="20" />
            </div>
            <br></br>
            <h id="tabelLabel" >8 game Win-Lose threshold history for teams involved in todays match ups</h>       
            {contents}
            <div class="p7"> <img class="img3" src="images/ts-logo.png" alt="Tallysight" /><a href="https://tallysight.com/membership/andrew-dineen" > Expert opinions/views/predictions which are sourced from here can be accessed on Tallysight </a></div>
      </div>
    );
  }

  async populateSequences() {
      const response = await fetch('https://ipsoa-api.azurewebsites.net/api/GetTodaysSequences?sport=2');
    const data = await response.json();
    this.setState({ Sequences: data, loading: false });
    } 

    static getleagueicon(competitionid) {
        var imgtag;
        imgtag = "images/" + competitionid + ".png";
        return imgtag;
    }

    static getimageflag(countrycode) {
        var imgtag;
        countrycode = countrycode.toLowerCase();
        imgtag = "images/" + countrycode + ".svg";
        return imgtag;
    }

    static getsequenceformat(sequencecode) {
        var codebits = [];
        var returnformat = "SequenceRegular"
        if (sequencecode != null) {
            codebits = sequencecode.split("-");
            if (codebits[3] >= codebits[0].substring(0, 1) | codebits[3] <= codebits[2].substring(0, 1)) {
                returnformat = "SequenceHighlight"
            }
        }
        return returnformat;
    }

    static getsporticon(sportid) {
        var imgtag;
        switch (sportid) {
            case 1:
                imgtag = "fas fa-futbol";
                break;
            case 2:
                imgtag = "fas fa-hockey-puck";
                break;
            default:
                imgtag = "fas fa-basketball-ball";
        }       
        return imgtag;
    }

    static getsequencestyle(sequencecodew, sequencecodel) {
        var codebitsw = [];
        var codebitsl = [];
        const liStyleGreen = { color: 'Green' };
        const liStyleRed = { color: 'Red' };
        const liStyledefault = { color: 'black' };
        var stylereturn;
        stylereturn = liStyledefault;
        if (sequencecodew != null) {
            codebitsw = sequencecodew.split("-");
            codebitsl = sequencecodel.split("-");
            if (codebitsw[3] >= codebitsw[0].substring(0, 1)) { stylereturn = liStyleGreen; }
            if (codebitsw[3] <= codebitsw[2].substring(0, 1)) { stylereturn = liStyleRed; }
            if (codebitsl[3] >= codebitsl[0].substring(0, 1)) { stylereturn = liStyleRed; }
            if (codebitsl[3] <= codebitsl[2].substring(0, 1)) { stylereturn = liStyleGreen; }

        }
        return stylereturn;
    }
    static getStatStyle(action) {
        const liStyleGreen = { color: 'Green' };
        const liStyleRed = { color: 'Red' };
        const liStyleamaber = { color: 'orange' };
        const liStyledefault = { color: 'black' };
        var stylereturn;
        switch (action) {
            case 3:
                stylereturn = liStyleGreen;
                break;
            case 2:
                stylereturn = liStyleamaber;
                break;
            case 1:
                stylereturn = liStyleRed;
                break;
            default:
                stylereturn = liStyledefault;
        }
        return stylereturn;
    }

    static getsequenceclass(sequencecodew, sequencecodel)
    {
        var codebitsw = [];
        var codebitsl = [];

        var classreturn;
        classreturn = "SequenceTeamRegular";
        if (sequencecodew != null) {
            codebitsw = sequencecodew.split("-");
            codebitsl = sequencecodel.split("-");
            if (codebitsw[3] >= codebitsw[0].substring(0, 1)) { classreturn = "SequenceTeamHighlightPlus"; }
            if (codebitsw[3] <= codebitsw[2].substring(0, 1)) { classreturn = "SequenceTeamHighlightMinus"; }
            if (codebitsl[3] >= codebitsl[0].substring(0, 1)) { classreturn = "SequenceTeamHighlightMinus"; }
            if (codebitsl[3] <= codebitsl[2].substring(0, 1)) { classreturn = "SequenceTeamHighlightPlus"; }

        }
        return classreturn;
    }

   

}
