import React, { Component } from 'react';
import moment from 'moment';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);

//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'       
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

export class SoccerTodayPP extends Component {
    static displayName = SoccerTodayPP.name;

  constructor(props) {
    super(props);
      this.state = { matches: [], loading: true, firstpopulate: true};
    }
   
    intervalID;

  componentDidMount() {
      this.populatePlayerData(this.state.firstpopulate);          
      this.intervalID = setInterval(this.populatePlayerData.bind(this), 2000);
     
    } 
    componentWillUnmount() {
        // stop getData() from continuing to run even  after unmounting this component        
        clearInterval(this.intervalID);
    }
  
   
    static renderPlayerDataTable(PlayerData) {            
        var history = [];
        var matchlist = [];
        var fixtures = []; 
        var statuscheck = "FT"
        var competitionname = "";
        var competitionid = -1;
        var competitionnamecheck = "";
        var countrycode = "";
        var imagetag = "";
        var imgicon = "";
        var pnghome = "";
        var pngaway = "";

        for (var i = 0; i < PlayerData.length; i++) {
            History = [];
           
            var match = PlayerData[i]; 
            var status = match[0]["Status"]; 
            if (statuscheck.indexOf(status) == -1)
            {
                var team1 = match[0]["Team1"];
                var team2 = match[0]["Team2"];
                competitionid = match[0]["competitionid"];
                competitionname = match[0]["competitionname"];
                countrycode = match[0]["countrycode"];
                imagetag = this.getimageflag(countrycode);
                pnghome = "https://media.api-sports.io/football/teams/" + match[0]["APIHomeID"] + ".png";
                pngaway = "https://media.api-sports.io/football/teams/" + match[0]["APIAwayID"] + ".png";

                var team1PlayerData = this.renderPlayerData(match[0]["playerData"][1])
                var team2PlayerData = this.renderPlayerData(match[0]["playerData"][2])
                if (competitionname != competitionnamecheck) {
                    imgicon = this.getleagueicon(competitionid);
                    fixtures.push(<tr><td height = "10px" colspan="3"></td> </tr>);

                    fixtures.push(<tr><td colspan = "3"><img src={imagetag} /><img class="img3" src={imgicon} />&nbsp;{competitionname}</td> </tr>);
                    competitionnamecheck = competitionname;
                }
                fixtures.push(<tr class="p7"><td>  <img class="img6" src={pnghome} /> {team1} </td><td>V</td><td align="right">  <img class="img6" src={pngaway} /> {team2} </td></tr>)
                fixtures.push(<tr><td> {team1PlayerData} </td><td></td><td align="right"> {team2PlayerData} </td></tr>)
            }
        }
        return (
            <div class="tb">  
                <div class="icon-container">
                    <i class="fas fa-futbol" title="football"></i>
                    <img src="images/gb-eng.svg" alt="England" width="38" height="20" />
                    <img src="images/it.svg" alt="Italy" width="38" height="20" />
                    <img src="images/es.svg" alt="Spain" width="38" height="20" />
                    <img src="images/de.svg" alt="Germany" width="38" height="20" />
                    <img src="images/fr.svg" alt="France" width="38" height="20" />
                    <img src="images/nl.svg" alt="Netherlands" width="38" height="20" />
                    <img src="images/pt.svg" alt="Portugal" width="38" height="20" />
                    <img src="images/be.svg" alt="Belgium" width="38" height="20" />
                    <img src="images/ar.svg" alt="Argentina" width="38" height="20" />
                </div>
                <br /><br />
               
               
                <table class="t5">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr><td colspan="3">
                            <div class="p12">  Apps = Number of starting 11 appearances  + appearances where the player scored or assisted from the bench</div>
                            <div class="p12">  TCC = Team Contribution Coefficient - A measure of the relative contribution the player has made to the teams successes. <br />This takes account of  wins , goals, assists and clean sheets as is governed by appearances made by the player. <br /><b>The sum of the entire TCCs for the team is 100</b></div>
                            <div class="p12" style={this.getStatStyle(3)}>  Player in todays starting line up </div>
                            <div class="p12" style={this.getStatStyle(2)}>  Player starts from the bench today </div>
                            <div class="p12" style={this.getStatStyle(1)}>  Player not available today </div>
                            <div class="p12" style={this.getStatStyle(0)}>  Todays team sheets not yet available </div>
                            <p class="p12"> <i class="fas fa-futbol"></i>  <a href="\LiveKOIs" ><span>Live feed - here</span> </a><i class="fas fa-futbol"></i><br />
                                <i class="fas fa-futbol"></i>  <a href="\fetch-data-soccer" ><span>In play - here</span> </a><i class="fas fa-futbol"></i><br />
                                <i class="fas fa-futbol"></i><a href="\SoccerTeams" ><span> Teams - here</span> </a><i class="fas fa-futbol"></i>

                            </p>

                        </td></tr>
                    </thead>
                    {fixtures}
                </table>
                <p /><br />
               
            </div>
        );
    }

    static renderPlayerData(PlayerData) {
        var matches = [];
        matches.push(<tr class="p7"><td >Player</td><td >Shirt</td><td >Apps</td> <td >TCC</td></tr>)
        for (var j = 0; j < PlayerData.length; j++) {
            var stylecolor = this.getStatStyle(PlayerData[j]["availableStatus"])          
            matches.push(<tr style={stylecolor}><td >{PlayerData[j]["player"]}</td>
                <td>{PlayerData[j]["shirtNo"]}</td>
                <td>{PlayerData[j]["appearances11"]}</td>
                <td>{PlayerData[j]["meritCoefficient"]}</td>
                </tr>)
        }
        return (
            <React.Fragment>
               <table> {matches} </table>
            </React.Fragment>)

    }

    static getStatStyle(action) {
        const liStyleGreen = { color: 'Green', 'font-weight': 'bold'};
        const liStyleRed = { color: 'Red', 'font-weight': 'bold'};
        const liStyleamaber = { color: 'orange', 'font-weight': 'bold'};
        const liStyledefault = { color: 'White', 'font-weight': 'bold'};
        var stylereturn;
        switch (action) {
            case 3:
                stylereturn = liStyleGreen;
                break;
            case 2:
                stylereturn = liStyleamaber;
                break;  
            case 1:
                stylereturn = liStyleRed;
                break; 
            default:
                stylereturn = liStyledefault;
        }
        return stylereturn;
    }

  render() {
    let contents = this.state.loading
        ? <p>
            <div class="icon-container">
                <i class="fas fa-futbol" title="football"></i>
                <img src="images/gb-eng.svg" alt="England" width="38" height="20" />
                <img src="images/it.svg" alt="Italy" width="38" height="20" />
                <img src="images/es.svg" alt="Spain" width="38" height="20" />
                <img src="images/de.svg" alt="Germany" width="38" height="20" />
                <img src="images/fr.svg" alt="France" width="38" height="20" />
                <img src="images/nl.svg" alt="Netherlands" width="38" height="20" />
                <img src="images/pt.svg" alt="Portugal" width="38" height="20" />
                <img src="images/be.svg" alt="Belgium" width="38" height="20" />
                <img src="images/ar.svg" alt="Argentina" width="38" height="20" />
            </div>
            <br /><br/> <br/><br/> <br/><br/>
            <div><em>Player profiles for all todays fixtures will appear here shortly .....</em></div></p>
        : SoccerTodayPP.renderPlayerDataTable(this.state.matches);

    return (
        <div>  
           
        <br/>
            <h id="tabelLabel" > Top player profiles for all major Soccer matchups </h>       
        {contents}
      </div>
    );
  }

    async populatePlayerData(firstcall) {

      try {

          let response = await fetch
              ('https://ipsoa-api.azurewebsites.net/api/TodaysFixtures?sport=1');
          if (!response.ok) {
              alert('Network response was not ok');
          }
          let jsonResponse = await response.json();
          let Matches = jsonResponse;
          var i;
          var j;
          j = 0;
          var matchdata = [];
          for (i = 0; i < Matches.length; i++) {
              var match = Matches[i];             
              var competitionid = match.competitionId;
              var competitionname = match.competitionName;
              var countrycode = match.countryCode;
              var Team1 = match.home;
              var Team2 = match.away;
              var Team1Id = match.homeId;
              var Team2Id = match.awayId;
              var FixtureId = match.fixtureId;
              var playerData;
              var Status = match.status;
              var APIHomeID = match.homeAPIId;
              var APIAwayID = match.awayAPIId;
              if (!(firstcall) | i < 3) {
                   playerData = await this.getPlayerData(Team1Id, Team2Id).then();
              }
              var matchlist = [{ Team1, Team2, competitionid, competitionname, countrycode, playerData, Status, APIHomeID, APIAwayID}];
              matchdata[i] = matchlist;
          }
          firstcall = false;
         
          this.setState({ matches: matchdata, loading: false, firstpopulate: firstcall  });

      }
      catch (err) {
          // catches errors both in fetch and response.json
          // alert(err.message);  throw this away for now as we cannot send popups to user here
          return 'trying';
      };
    } 

    async getPlayerData(team1Id, team2Id) {
        try {
            var playerData = [];
            let url = 'https://ipsoa-api.azurewebsites.net/api/TeamPlayers?TeamId=' + team1Id + '&sport=1';
            let response = await fetch(url);
            let data = await response.json();
            playerData[1] = data;

            url = 'https://ipsoa-api.azurewebsites.net/api/TeamPlayers?TeamId=' + team2Id + '&sport=1';
            response = await fetch(url);
            data = await response.json();
            playerData[2] = data;
        }

        catch (err) {
            // catches errors both in fetch and response.json
            // alert(err.message);  throw this away for now as we cannot send popups to user here
           
        };
        return playerData

    }
    static getleagueicon(competitionid) {
        var imgtag;
        imgtag = "images/" + competitionid + ".png";
        return imgtag;
    }

    static getimageflag(countrycode) {
        var imgtag;
        countrycode = countrycode.toLowerCase();
        imgtag = "images/" + countrycode + ".svg";
        return imgtag;
    }

    static getsporticon(sportid) {
        var imgtag;
        switch (sportid) {
            case 1:
                imgtag = "fas fa-futbol";
                break;
            case 2:
                imgtag = "fas fa-hockey-puck";
                break;
            default:
                imgtag = "fas fa-Soccer-ball";
        }       
        return imgtag;
    }

   

}
