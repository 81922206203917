import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip, ArcElement, LinearScale } from 'chart.js';
import { Accordion, AccordionItem, AccordionItemHeading,  AccordionItemButton,   AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactGA from 'react-ga';
Chart.register(BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip, ArcElement, LinearScale);
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);

//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'
//        /* ...Other Configuration Options... */
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

const ChartStyle = { width: '100px'};
const InlineStyle = { display: 'inline-block' };

export class FetchDataSoccer extends Component {
    static displayName = FetchDataSoccer.name;

    constructor(props) {
        super(props);
        this.state = { scores: [], goalsData: [], loading: true, expanded: false, currentdateandtime: "", mobileclient: false, firstpopulate: true};
    }

    intervalID;
    componentDidMount() {
        this.state.goalsData = [];
        this.state.mobileclient = detectMobile();
        this.populateScoreData(this.state.goalsData, this.state.firstpopulate);
        // Need to make this run at a specified interval            
        this.intervalID = setInterval(this.updateScores.bind(this), 2000);
        function detectMobile() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i];

            var bDevice = toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
            var bResolution = ((window.innerWidth <= 800) && (window.innerHeight <= 600))
            return (bDevice || bResolution);
        };
    }

    componentWillUnmount() {
        // stop getData() from continuing to run even  after unmounting this component        
        clearInterval(this.intervalID);
    }
    async updateScores() {
        this.populateScoreData(this.state.goalsData, this.state.firstpopulate);
    }
    static renderScoresTable(scores, goals, mobileclient,firstcall) {
        var lines = [];       
        var Summary = [];
        var fixtures = [];
        var match;
        var matchsummary;
        var matchstats;
        var chart;
        var matchstatsline = "";
        var team1 = "";
        var team2 = "";
        var competitionid = -1;
        var competitionname = "";
        var competitionnamecheck = "";
        var countrycode = "";
        var imagetag = "";
        var imgicon = "";
        var pnghome = "";
        var pngaway= "";
        var labels = [];
        var chartitems = [];
        var clientclass = "accordion__item_browser";
        if (mobileclient)
            clientclass = "accordion__item_mobile";

       
        for (var i = 1; i < scores.length; i++) {
            lines = [];
            Summary = [];           
            match = scores[i];
            matchsummary = match[0]["scoreline"];
            matchstats = match[0]["matchstats"];
            competitionid = match[0]["competitionid"];
            competitionname = match[0]["competitionname"];
            countrycode = match[0]["countrycode"];
            imagetag = this.getimageflag(countrycode);
            team1 = match[0]["Team1"];
            team2 = match[0]["Team2"];
            pnghome = "https://media.api-sports.io/football/teams/" + match[0]["APIHomeID"] +".png" ;
            pngaway = "https://media.api-sports.io/football/teams/" + match[0]["APIAwayID"] + ".png";
            labels = [team2.substring(0, 8), team1.substring(0, 8),'None'];
            chartitems = match[0]["chartlist"];
           
            
            if (matchsummary.Score1 == '?' || matchsummary.Elapsed == 'FT') {
                Summary.push(<div class="matchsummary"><div class="matchsummary"> <img class="img6" src={pnghome} /> {matchsummary.Team1}</div> <div class="goal"> {matchsummary.Score1} </div> <div class="matchsummary"> <img class="img6" src={pngaway} /> {matchsummary.Team2} </div> <div class="goal">{matchsummary.Score2} </div><div class="matchsummary"> {matchsummary.Elapsed} </div> </div>);
            } else {
                Summary.push(<div class="matchsummary"><div class="matchsummaryhome"> <img class="img6" src={pnghome} /> {matchsummary.Team1}</div> <div class="goal"> {matchsummary.Score1} </div> <div class="matchsummaryaway"> <img class="img6" src={pngaway} /> {matchsummary.Team2} </div> <div class="goal">{matchsummary.Score2} </div><div class="matchsummary"> {matchsummary.Elapsed} </div> </div>);
            }
            if (matchstats)
            {
                try {
                    if (chartitems.length != 0 & matchsummary.Elapsed != 'FT') {
                        var dataitems = chartitems[0][0]["value"];
                        var header = chartitems[0][0]["key"];
                        chart = this.renderChart(labels, dataitems, header);
                        //lines.push(<div style={InlineStyle}> {chart} </div>);   
                    } else {
                        chart = [];
                    }
                }
                catch {
                    chart = [];
                      }
                               
                matchstatsline = this.renderStatsList(matchstats, goals, team1, team2, mobileclient);
                lines.push(<div > {matchstatsline} </div>);                
            }   

            if (competitionname != competitionnamecheck)
            {
                imgicon = this.getleagueicon(competitionid);
                fixtures.push(<br />);
                fixtures.push(<hr class="hlbreaker"/>);
                fixtures.push(<div class="league"><img src={imagetag} /><img class="img3" src={imgicon} />&nbsp;{competitionname} </div>);
                fixtures.push(<br />);
                fixtures.push(<br />);
                competitionnamecheck = competitionname;
            }
            fixtures.push(
                <div>
                   
                    <div class ="p2" > <i class="fas fa-futbol"> </i> {Summary}</div>
                    <div style={InlineStyle}> {chart} </div>
                <Accordion allowZeroExpanded="true">
                    <AccordionItem class={clientclass}>
                        <AccordionItemHeading >
                            <AccordionItemButton>
                                    KOIs - click here
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {lines}
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion> </div>)
        }
        return (
            <div>
                {fixtures}
            </div>
        );
    }

    static renderStatsList(stats, goals, team1, team2, mobileclient) {
        var lines = [];
        var matchstatsarray = [];
        var matchstatsline;
        var goalscored = "";
        var team = "";
        var goalskey = ""
        var stylecolor;
        var styleparam;
        var clientclassaccordion = "accordion__item_browser";
        var clientclassgoals = "goals_browser";
        if (mobileclient) {
            clientclassaccordion = "accordion__item_mobile";
            clientclassgoals = "goals_mobile";
        }

        for (var j = 0; j < stats.length; j++) {
            matchstatsarray = stats[j];
            matchstatsline = matchstatsarray[0]["value"];
            styleparam = matchstatsarray[0]["style"]
            goalskey = matchstatsarray[0]["key"];
            stylecolor = this.getStatStyle(styleparam);
            if (styleparam == 1) { team = team1 } else { team = team2 }
            goalscored = this.GetTeamGoalsContext(goals, team, goalskey)
            lines.push(<Accordion allowZeroExpanded="true" >
                <AccordionItem class={clientclassaccordion}>
                    <AccordionItemHeading>
                        <AccordionItemButton style={stylecolor}>
                            {matchstatsline}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div class={clientclassgoals}> {goalscored}</div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion >);
        }
        return (
            <React.Fragment>
                {lines}
            </React.Fragment>)
    }
    static renderChart(labels,data, header) {
        var lines = [];  
        lines.push(<div style={ChartStyle}>
            <Doughnut data=
                {{
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: ['RoyalBlue', 'Crimson', '#F2F2F2'],
                        hoverBackgroundColor: ['RoyalBlue ', 'Crimson', '#F2F2F2']
                    }]
                }}
                options={{
                    responsive: true,
                    plugins: {
                        datalabels: {
                            formatter: function (value, context) {
                                return context.chart.data.labels[
                                    context.dataIndex
                                ];
                            },
                        },
                        title: {
                            display: true,
                            text: header,
                            color: "#696969",
                            font: {
                                family: "AvenirNextLTW01-Regular",
                                size: 12,
                                style: 'normal'
                            }
                        },
                        legend: {
                            display: false
                        }
                    },

                }} />

        </div>);
        return (
            <React.Fragment>
                {lines}
            </React.Fragment>)
    }
    static getStatStyle(action) {
        const liStyleGreen = { color: 'Green', height: '40px', font: 'Montserrat' };
        const liStyleRed = { color: 'RoyalBlue', height: '40px', font: 'Montserrat' };
        const liStyledefault = { color: 'Crimson', height: '40px', font: 'Montserrat' };
        var stylereturn;
        switch (action) {
            case 1:
                stylereturn = liStyledefault;
                break;
            case 2:
                stylereturn = liStyleRed;
                break;
            default:
                stylereturn = liStyledefault;
        }
        return stylereturn;
    }
    static getimageflag(countrycode) {
        var imgtag;
        countrycode = countrycode.toLowerCase();
        imgtag = "images/" + countrycode +".svg";       
        return imgtag;
    }
    static getleagueicon(competitionid) {
        var imgtag;
        imgtag = "images/" + competitionid +".png";  
        return imgtag;
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : FetchDataSoccer.renderScoresTable(this.state.scores, this.state.goalsData, this.state.mobileclient, this.state.firstpopulate);
        return (<div><p>{this.state.currentdateandtime}</p>
            <p class="p5"> Showcase of upcoming Soccer action </p>  
            <p> <br/> </p>
            <p> <b>NOTE: All analytics will update on a minute by minute basis once the games are in play</b></p>
            
            <p>Big inplay KOI's also tweeted to followers on twitter - <a href="https://twitter.com/sportoutcomes?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @sportoutcomes</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></p>
            
            <p> <br />
                <p class="p8">
                    <i class="fas fa-futbol"></i>  <a href="\LiveKOIs" ><span>Live feed - here</span> </a><i class="fas fa-futbol"></i><br/>
                    <i class="fas fa-futbol"></i><a href="\SoccerPlayers" ><span> Players - here</span> </a><i class="fas fa-futbol"></i><br/>
                    <i class="fas fa-futbol"></i>  <a href="\SoccerTeams" ><span>Teams - here</span> </a><i class="fas fa-futbol"></i></p>
              <p> <br/> </p>
                <p> Show live games only <input type="checkbox" vertical-align="bottom" id="CkLiveGames" /></p>
                <br/>
            </p>
            {contents}
            <p />
           
            <p class="p3"> <a href="mailto:development@sportoutcomes.com">Report Issue</a></p>
            <p>The material and information provided on this site is for general information purposes only.<br/> You should not rely upon this material or information as a basis for making any business, legal or any other decisions.</p>
        </div>);
    }

    async populateScoreData(goals, firstcall) {

        try {

            let response = await fetch
                ('https://ipsoa-api.azurewebsites.net/api/TodaysFixtures?');
            if (!response.ok) {
                alert('Network response was not ok');
            }
            let jsonResponse = await response.json();
            let Matches = jsonResponse;
            var i;
            var j;
            j = 0;
            var scoredata = [];
            for (i = 0; i < Matches.length; i++) {
                var match = Matches[i];
                var competitionid = match.competitionId;
                var competitionname = match.competitionName;
                var countrycode = match.countryCode;
                var Team1 = match.home;
                var Team2 = match.away;
                var Team1Id = match.homeId;
                var Team2Id = match.awayId;
                var FixtureId = match.fixtureId;
                var Score1 = match.homeScore;
                var Score2 = match.awayScore;
                var Elapsed = match.elapsed;
                var APIHomeID = match.homeAPIId;
                var APIAwayID = match.awayAPIId;
                Elapsed = await this.AdjustStatus(Elapsed);
                if (Elapsed == '?') { Elapsed = ''; }
                var scoreline = { Team1, Team2, Score1, Score2, Elapsed };
                var matchstats;
                var chartlist = [];                
                if (Elapsed == 'HT') { Elapsed = 45; }
                if (Elapsed == '') { Elapsed = 0; }
                if (Elapsed >= 90) { Elapsed = 90; }
                //Elapsed = Elapsed.replace("+", "");
                var matchstatus = Elapsed;
                var checkBox = document.getElementById("CkLiveGames");
                if ((checkBox.checked != true) | (Elapsed |= 'FT')) {
                    if (!(firstcall) | i < 3) {
                        matchstats = await this.getMatchStatsList(FixtureId, Elapsed).then();
                        if (matchstatus != 'FT' ) {
                            chartlist = await this.getMatchStatsChart(FixtureId, Elapsed, Score1, Score2).then();
                        }
                        var matchlist;
                        goals = await this.getGoalsData(goals, Team1, Team2, Team1Id, Team2Id).then();
                    }
                    matchlist = [{ Team1, Team2, scoreline, matchstats, chartlist, competitionid, competitionname, countrycode, APIHomeID, APIAwayID }];
                    j = j + 1;
                    scoredata[j] = matchlist;
                }
            }
            var cdate = new Date().toUTCString();
            cdate = cdate.substring(cdate.length - 12, cdate.length)
            firstcall = false;           
            this.setState({ scores: scoredata, goalsData: goals, loading: false, currentdateandtime: cdate, firstpopulate: firstcall});

        }
        catch (err) {
            // catches errors both in fetch and response.json
            // alert(err.message);  throw this away for now as we cannot send popups to user here
            return 'trying';
        };
    }

    async AdjustStatus(status) {
        // put out the correct time for the time zone of browser/mobile user
        //if (status) {
        //    status = status.replace('+', '');
        //    if (status.indexOf(":") == 2 || status.length == 5) {
        //        var cdate = new Date();
        //        var offset = cdate.getTimezoneOffset() / 60;
        //        var sdate = "";
        //        sdate = cdate.getFullYear() + '/' +
        //            (cdate.getMonth() + 1) + '/' +
        //            cdate.getDate() + ' ' + status
        //        cdate = new Date(sdate);
        //        cdate.setHours(cdate.getHours() - offset);
        //        status = (cdate.getHours() < 10 ? '0' : '') + cdate.getHours() + ':' + (cdate.getMinutes() < 10 ? '0' : '') + cdate.getMinutes();
        //    }
        //}
        return status;
    }

    async getMatchStatsList(fixtureId, gameMinute) {
        try {
            let url = 'https://ipsoa-api.azurewebsites.net/api/KOIs?matchId=' + fixtureId + '&gameMinute=' + gameMinute + '';
            let response = await fetch(url);
            let data = await response.json();
            var itemcounter
            var statlist = [];
            itemcounter = 0;
            if (data.fgH_action == '1') {
                statlist[itemcounter] = [{ key: "fgh", value: data.fgh, style: 1 }];
                itemcounter += 1;
            }
            if (data.asH_action == '1') {
                statlist[itemcounter] = [{ key: "ash", value: data.ash, style: 1 }];
                itemcounter += 1;
            }
            if (data.h2GH_action == '1') {
                statlist[itemcounter] = [{ key: "h2GH", value: data.h2GH, style: 1 }];
                itemcounter += 1;
            }
            if (data.fgcH_action == '1') {
                statlist[itemcounter] = [{ key: "fgch", value: data.fgch, style: 1 }];
                itemcounter += 1;
            }
            if (data.cbhE_action == '1') {
                statlist[itemcounter] = [{ key: "cbhe", value: data.cbhe, style: 1 }];
                itemcounter += 1;
            }
            if (data.b2GH_action == '1') {
                statlist[itemcounter] = [{ key: "b2GH", value: data.b2GH, style: 1 }];
                itemcounter += 1;
            }
            if (data.hdB_action == '1') {
                statlist[itemcounter] = [{ key: "hdb", value: data.hdb, style: 1 }];
                itemcounter += 1;
            }
            if (data.ilgH_action == '1') {
                statlist[itemcounter] = [{ key: "ilgh", value: data.ilgh, style: 1 }];
                itemcounter += 1;
            }
            if (data.eqhS_action == '1') {
                statlist[itemcounter] = [{ key: "eqhs", value: data.eqhs, style: 1 }];
                itemcounter += 1;
            }
            if (data.eqhC_action == '1') {
                statlist[itemcounter] = [{ key: "eqhc", value: data.eqhc, style: 1 }];
                itemcounter += 1;
            }
            if (data.fgA_action == '1') {
                statlist[itemcounter] = [{ key: "fga", value: data.fga, style: 2 }];
                itemcounter += 1;
            }
            if (data.asA_action == '1') {
                statlist[itemcounter] = [{ key: "asa", value: data.asa, style: 2 }];
                itemcounter += 1;
            }
            if (data.h2GA_action == '1') {
                statlist[itemcounter] = [{ key: "h2GA", value: data.h2GA, style: 2 }];
                itemcounter += 1;
            }
            if (data.fgcA_action == '1') {
                statlist[itemcounter] = [{ key: "fgca", value: data.fgca, style: 2 }];
                itemcounter += 1;
            }
            if (data.cbaE_action == '1') {
                statlist[itemcounter] = [{ key: "cbae", value: data.cbae, style: 2 }];
                itemcounter += 1;
            }
            if (data.b2GA_action == '1') {
                statlist[itemcounter] = [{ key: "b2GA", value: data.b2GA, style: 2 }];
                itemcounter += 1;
            }
            if (data.adB_action == '1') {
                statlist[itemcounter] = [{ key: "adb", value: data.adb, style: 2 }];
                itemcounter += 1;
            }
            if (data.ilgA_action == '1') {
                statlist[itemcounter] = [{ key: "ilga", value: data.ilga, style: 2 }];
                itemcounter += 1;
            }
            if (data.eqaS_action == '1') {
                statlist[itemcounter] = [{ key: "eqas", value: data.eqas, style: 2 }];
                itemcounter += 1;
            }
            if (data.eqaC_action == '1') {
                statlist[itemcounter] = [{ key: "eqac", value: data.eqac, style: 2 }];
                itemcounter += 1;
            }

            return statlist
        }
        catch (err) {
            // catches errors both in fetch and response.json
            //alert(err.message);
            return statlist;
        };
    }
    async getMatchStatsChart(fixtureId, gameMinute,score1, score2) {
        try {
            let url = 'https://ipsoa-api.azurewebsites.net/api/KOI-Numerics?matchId=' + fixtureId + '&gameMinute=' + gameMinute + '';
            let response = await fetch(url);
            let data = await response.json();
            var itemcounter
            var chartvalues = [];
            var statlist = [];
            if (score1 == '?') { score1 = 0; }
            if (score2 == '?') { score2 = 0; }
            itemcounter = 0;
            if (score1.valueOf() + score2.valueOf() == 0) {
                chartvalues = await this.GetChartValues(data.hdb, data.adb, 0)
                statlist[itemcounter] = [{ key: "First goal", value: chartvalues, style: 1 }];
                itemcounter += 1;
            }
            if (score2.valueOf() - score1.valueOf() == 1) {
                chartvalues = await this.GetChartValues(data.ilgh, data.ilga, 3)
                statlist[itemcounter] = [{ key: "Next goal", value: chartvalues, style: 1 }];
                itemcounter += 1;
            }
            if (score1.valueOf() - score2.valueOf()== 1) {
                chartvalues = await this.GetChartValues(data.ilgh, data.ilga, 3)
                statlist[itemcounter] = [{ key: "Next goal", value: chartvalues, style: 1 }];
                itemcounter += 1;
            }

            if (score1.valueOf() == score2.valueOf()) {
                chartvalues = await this.GetChartValues(data.ilgh, data.ilga, 3)
                statlist[itemcounter] = [{ key: "Next goal", value: chartvalues, style: 1 }];
                itemcounter += 1;
            }
            
            return statlist
        }
        catch (err) {
            // catches errors both in fetch and response.json
            //alert(err.message);
            return statlist;
        };
    }

    async getGoalsData(goalsdata, team1, team2, team1Id, team2Id) {
        try {           
            var goalsscored = "";
            var newIndex = 0;
            var goalsExist = false;
            goalsExist = await this.TeamGoalsExist(goalsdata, team1).then();
            if (!goalsExist) {
                let url = 'https://ipsoa-api.azurewebsites.net/api/GoalsRaw?TeamId=' + team1Id +  '';
                url = url.replace(/ /g, '%20');
                url = url.replace(/&/g, '%26');
                let response = await fetch(url);
                let data = await response.json();
                newIndex = goalsdata.length;
                goalsscored = await this.formatGoalsDataStruct(data);
                goalsdata[newIndex] = [{ Team: team1, goals: goalsscored }];
            }
            goalsExist = await this.TeamGoalsExist(goalsdata, team2).then();
            if (!goalsExist) {
                let url = 'https://ipsoa-api.azurewebsites.net/api/GoalsRaw?TeamId=' + team2Id + '';
                url = url.replace(/ /g, '%20');
                url = url.replace(/&/g, '%26');
                let response = await fetch(url);
                let data = await response.json();
                newIndex = goalsdata.length;
                goalsscored = await this.formatGoalsDataStruct(data);
                goalsdata[newIndex] = [{ Team: team2, goals: goalsscored }];
            }
            return goalsdata
        }
        catch (err) {
            // catches errors both in fetch and response.json
            // alert(err.message);  throw this away for now as we cannot send popups to user here
            return goalsdata;
        };
    }
    async formatGoalsData(data) {
        var returnstring = "";
        var goalsstring = "";
        var Goals1 = data.firstGoalsScored;
        var Goals2 = data.firstGoalsConceded;
        var Goals3 = data.equalisersScored;
        var Goals4 = data.equalisersConceded;
        var Goals5 = data.outcomeChangingGoalsScored;
        var Goals6 = data.outcomeChangingGoalsConceded;
        var Goals7 = data.twoGoalLeads;
        var Goals8 = data.twoGoalDeficits;
        goalsstring = "First Goals Scored;" + Goals1 + "\n First Goals Conceded;" + Goals2 + "\n Equalisers Scored;" + Goals3 + "\n Equalisers Conceded;" + Goals4 + "\n Outcome Changing Scored;" + Goals5 + "\n Outcome Changing Conceded;" + Goals6 + "\n Two goal leads;" + Goals7 + "\n Two goal deficits;" + Goals8;
        returnstring = goalsstring.toString().replace(/;/g, '\n');
        return returnstring;
    }
    async formatGoalsDataStruct(data) {
        var goalsdata = [];
        var Goals1 = "First Goals Scored\n" + data.firstGoalsScored.toString().replace(/;/g, '\n');

        var Goals2 = "First Goals Conceded \n" + data.firstGoalsConceded.toString().replace(/;/g, '\n');
        var Goals3 = "Equalisers Scored \n" + data.equalisersScored.toString().replace(/;/g, '\n');
        var Goals4 = "Equalisers Conceded \n" + data.equalisersConceded.toString().replace(/;/g, '\n');
        var Goals5 = "Outcome Changing Goals Scored \n" + data.outcomeChangingGoalsScored.toString().replace(/;/g, '\n');
        var Goals6 = "Outcome Changing Goals Conceded \n" + data.outcomeChangingGoalsConceded.toString().replace(/;/g, '\n');
        var Goals7 = "Two goal leads \n" + data.twoGoalLeads.toString().replace(/;/g, '\n');
        var Goals8 = "Two goal deficits \n" + data.twoGoalDeficits.toString().replace(/;/g, '\n');

        goalsdata[0] = [{ key: "fgs", value: Goals1 }];
        goalsdata[1] = [{ key: "fgc", value: Goals2 }];
        goalsdata[2] = [{ key: "eqs", value: Goals3 }];
        goalsdata[3] = [{ key: "eqc", value: Goals4 }];
        goalsdata[4] = [{ key: "igs", value: Goals5 }];
        goalsdata[5] = [{ key: "igc", value: Goals6 }];
        goalsdata[6] = [{ key: "tgl", value: Goals7 }];
        goalsdata[7] = [{ key: "tgd", value: Goals8 }];
        return goalsdata;
    }
    async TeamGoalsExist(goals, team) {
        var found = false;
        var teamcheck = "";
        try {
            for (var i = 0; i < goals.length; i++) {
                teamcheck = goals[i][0]["Team"];
                if (teamcheck == team) {
                    found = true;
                    break;
                }
            }
        }
        catch (err) {
            // alert(err.message);  throw this away for now as we cannot send popups to user here
        };
        return found
    }
    static GetTeamGoals(goals, team) {
        var teamcheck = "";
        var teamgoals = "";
        try {
            for (var i = 0; i < goals.length; i++) {
                teamcheck = goals[i][0]["Team"];
                if (teamcheck == team) {
                    teamgoals = goals[i][0]["goals"];
                    break;
                }
            }
        }
        catch (err) {
            // alert(err.message);  throw this away for now as we cannot send popups to user here
        };
        return teamgoals
    }
    async GetChartValues(homestat, awaystat,homeaway) {
        var homestats =  homestat.split("-");
        var awaystats = awaystat.split("-");
        var matchnumbers = [];
        var a = homestats[0];
        var b = homestats[1];
        var c = 0;
        if (homestats.length == 3) {c = homestats[2]; }
        var d = awaystats[0];
        var e = awaystats[1];
        var f = 0;
        if (awaystats.length == 3) {f = awaystats[2]; }
        try {
            switch (homeaway)
            {
                case 0:
                    matchnumbers = [Number(e) + Number(c), Number(b) + Number(f), Number(a) + Number(d)];
                    break;
                case 1:
                    matchnumbers = [Number(b) + Number(e), Number(a) + Number(d), Number(c) + Number(f)];
                    break;
                case 2:                  
                    matchnumbers = [Number(d) + Number(a), Number(b) + Number(e) ,Number(c) + Number(f)];
                    break;
                case 3:
                    matchnumbers = [Number(d) + Number(b), Number(a) + Number(e) ,Number(c) + Number(f)];
                    break;

            }
            
        }
        catch (err) {
            // alert(err.message);  throw this away for 
        };
        return matchnumbers
    }
    static GetTeamGoalsContext(goals, team, key) {
        var teamcheck = "";
        var keycheck = "";
        var teamgoals = "";
        var goalskey = "";
        var goalscheck = [];
        try {

            switch (key) {
                case "fgh":
                case "fga":
                    goalskey = "fgs"
                    break;
                case "fgch":
                case "fgca":
                    goalskey = "fgc"
                    break;
                case "ash":
                case "asa":
                    goalskey = "eqc"
                    break;
                case "eqhc":
                case "eqac":
                    goalskey = "eqc:tgl"
                    break;
                case "cbae":
                case "cbhe":
                    goalskey = "eqs"
                    break;
                case "eqhs":
                case "eqas":
                    goalskey = "eqs:tgd"
                    break;
                case "ilgh":
                case "ilga":
                    goalskey = "igs:igc"
                    break;
                case "h2GH":
                case "h2GA":
                    goalskey = "tgl"
                    break;
                case "b2GH":
                case "b2GA":
                    goalskey = "tgd"
                    break;
                case "adb":
                case "hdb":
                    goalskey = "fgs:fgc"
                    break;
                default:
            }
            if (goalskey != "") {
                for (var i = 0; i < goals.length; i++) {
                    teamcheck = goals[i][0]["Team"];
                    if (teamcheck == team) {
                        goalscheck = goals[i][0]["goals"];
                        for (var j = 0; j < goalscheck.length; j++) {
                            var keycheck = goalscheck[j][0]["key"];
                            if (goalskey.indexOf(keycheck) > -1) {
                                if (teamgoals != '') { teamgoals = teamgoals + ' \n\n' }
                                teamgoals = teamgoals + goalscheck[j][0]["value"];
                            }
                        }
                    }
                }
            }
        }
        catch (err) {
            // alert(err.message);  throw this away for now as we cannot send popups to user here
        };
        return teamgoals
    }


}
