import React, { Component } from 'react';
import moment from 'moment';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);

//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'       
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

export class SoccerTodayHY extends Component {
    static displayName = SoccerTodayHY.name;

  constructor(props) {
    super(props);
      this.state = { matches: [], loading: true };
    }
   
    intervalID;

  componentDidMount() {
      this.populateMatchHistory();     
     
    } 
   
    static renderMatchHistoryTable(matchhistory) {            
        var history = [];
        var matchlist = [];
        var fixtures = []; 
        var statuscheck = "FT"
        var competitionname = "";
        var competitionid = -1;
        var competitionnamecheck = "";
        var countrycode = "";
        var imagetag = "";
        var imgicon = "";
        var pnghome = "";
        var pngaway = "";
        for (var i = 0; i < matchhistory.length; i++) {
            History = [];
           
            var match = matchhistory[i]; 
            var status = match[0]["Status"]; 
            if (statuscheck.indexOf(status) == -1)
            {
                var team1 = match[0]["Team1"];
                var team2 = match[0]["Team2"];
                competitionid = match[0]["competitionid"];
                competitionname = match[0]["competitionname"];
                countrycode = match[0]["countrycode"];
                imagetag = this.getimageflag(countrycode);
                pnghome = "https://media.api-sports.io/football/teams/" + match[0]["APIHomeID"] + ".png";
                pngaway = "https://media.api-sports.io/football/teams/" + match[0]["APIAwayID"] + ".png";

                var team1history = this.renderTeamHistory(match[0]["teamhistory"][1])
                var team2history = this.renderTeamHistory(match[0]["teamhistory"][2])
                if (competitionname != competitionnamecheck) {
                    imgicon = this.getleagueicon(competitionid);
                    fixtures.push(<tr><td height = "10px" colspan="3"></td> </tr>);

                    fixtures.push(<tr><td colspan = "3"><img src={imagetag} /><img class="img3" src={imgicon} />&nbsp;{competitionname}</td> </tr>);
                    competitionnamecheck = competitionname;
                }
                fixtures.push(<tr class="p7"><td> <img class="img6" src={pnghome} /> {team1} </td><td>V</td><td align="right"><img class="img6" src={pngaway} /> {team2} </td></tr>)
                fixtures.push(<tr class="p7"><td> {team1history} </td><td></td><td align="right"> {team2history} </td></tr>)
            }
        }
        return (
            <div>  
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {fixtures}
                </table>
            </div>
        );
    }

    static renderTeamHistory(matchHistory) {
        var matches =[];
        for (var j = 0; j < matchHistory.length; j++) {
            var stylecolor = this.getStatStyle(matchHistory[j]["win"])
            matches.push(<div class ="p12" style={stylecolor}>{matchHistory[j]["game"]}</div>)
        }
        return (
            <React.Fragment>
                {matches}
            </React.Fragment>)

    }

    static getStatStyle(action) {
        const liStyleGreen = { color: 'Green'};
        const liStyleRed = { color: 'Red' };
        const liStyledefault = { color: 'orange' };
        var stylereturn;
        switch (action) {
            case 2:
                stylereturn = liStyleGreen;
                break;  
            case 0:
                stylereturn = liStyleRed;
                break; 
            default:
                stylereturn = liStyledefault;
        }
        return stylereturn;
    }

  render() {
    let contents = this.state.loading
      ? <p></p>
        : SoccerTodayHY.renderMatchHistoryTable(this.state.matches);

    return (
        <div>  
           
        <br/>
            <h id="tabelLabel" > 8 Match history for all major Soccer matchups</h>       
        {contents}
      </div>
    );
  }

  async populateMatchHistory() {

      try {

          let response = await fetch
              ('https://ipsoa-api.azurewebsites.net/api/TodaysFixtures?sport=1');
          if (!response.ok) {
              alert('Network response was not ok');
          }
          let jsonResponse = await response.json();
          let Matches = jsonResponse;
          var i;
          var j;
          j = 0;
          var matchdata = [];
          for (i = 0; i < Matches.length; i++) {
              var match = Matches[i];             
              var competitionid = match.competitionId;
              var competitionname = match.competitionName;
              var countrycode = match.countryCode;
              var Team1 = match.home;
              var Team2 = match.away;
              var Team1Id = match.homeId;
              var Team2Id = match.awayId;
              var FixtureId = match.fixtureId; 
              var Status = match.status;
              var APIHomeID = match.homeAPIId;
              var APIAwayID = match.awayAPIId;
              var  teamhistory = await this.getHistoryData(Team1Id, Team2Id).then();  
              var matchlist = [{ Team1, Team2, competitionid, competitionname, countrycode, teamhistory, Status,APIHomeID, APIAwayID}];
              matchdata[i] = matchlist;
              }
         
          this.setState({ matches: matchdata,  loading: false,  });

      }
      catch (err) {
          // catches errors both in fetch and response.json
          // alert(err.message);  throw this away for now as we cannot send popups to user here
          return 'trying';
      };
    } 

    async getHistoryData(team1Id, team2Id) {
        try {
            var MatchHistory = [];
            let url = 'https://ipsoa-api.azurewebsites.net/api/MatchHistory?TeamId=' + team1Id + '&sport=1';
            let response = await fetch(url);
            let data = await response.json();
            MatchHistory[1] = data;

            url = 'https://ipsoa-api.azurewebsites.net/api/MatchHistory?TeamId=' + team2Id + '&sport=1';
            response = await fetch(url);
            data = await response.json();
            MatchHistory[2] = data;
        }

        catch (err) {
            // catches errors both in fetch and response.json
            // alert(err.message);  throw this away for now as we cannot send popups to user here
           
        };
        return MatchHistory

    }
    static getleagueicon(competitionid) {
        var imgtag;
        imgtag = "images/" + competitionid + ".png";
        return imgtag;
    }

    static getimageflag(countrycode) {
        var imgtag;
        countrycode = countrycode.toLowerCase();
        imgtag = "images/" + countrycode + ".svg";
        return imgtag;
    }

    static getsporticon(sportid) {
        var imgtag;
        switch (sportid) {
            case 1:
                imgtag = "fas fa-futbol";
                break;
            case 2:
                imgtag = "fas fa-hockey-puck";
                break;
            default:
                imgtag = "fas fa-Soccer-ball";
        }       
        return imgtag;
    }

   

}
