import React, { Component } from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);

//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'
//        /* ...Other Configuration Options... */
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

export class Home extends Component {
    static displayName = Home.name;
    render() {
        return (
            <div>
                <h1>Welcome to our Showcase.</h1>

               
               
                           <div>
                <i class="fas fa-futbol" title ="football"></i>
                <img src="images/gb-eng.svg" alt="England" width="38" height="20" />
                <img src="images/it.svg" alt="Italy" width="38" height="20" />
                <img src="images/es.svg" alt="Spain" width="38" height="20" />
                <img src="images/de.svg" alt="Germany" width="38" height="20" />
                <img src="images/fr.svg" alt="France" width="38" height="20" />
                <img src="images/nl.svg" alt="Netherlands" width="38" height="20" />
                <img src="images/pt.svg" alt="Portugal" width="38" height="20" />
                <img src="images/be.svg" alt="Belgium" width="38" height="20" />
                <img src="images/ar.svg" alt="Argentina" width="38" height="20" />
            &nbsp;<i class="fas fa-basketball-ball" title="basketball"></i>
                    <img src="images/us.svg" alt="North America" width="38" height="20" />
                    <img src="images/eu.svg" alt="Europe" width="38" height="20" />
           &nbsp; <i class="fas fa-hockey-puck" title="hockey"></i>
                    <img src="images/us.svg" alt="North America" width="38" height="20" />
                    <img src="images/eu.svg" alt="Europe" width="38" height="20" />
           &nbsp; <i class="fas fa-football-ball" title="Rugby"></i>
               <img src="images/eu.svg" alt="Europe" width="38" height="20" />
                    <p> <br /> </p>

                <Accordion allowZeroExpanded="true">
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Welcome to this unique approach to real time in play sport outcomes analysis
                    </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div class="intro">
                                <p>Up to the minute in play match outcome indicators are served up here <br />
                                 This is where key information is supplied based on the ability of teams to respond to various situations that arise in a game, taking account of both remaining time and the current status of the match<br />
                                 The underlying analysis reveals key <b>match outcome indicators</b> based on any of the following factors<br />
                                    <ul>
                                        <li>How a team has fared subsequently in the game having got the first score </li>
                                        <li>How a team has fared subsequently in the game having conceded the first score</li>
                                        <li>How well a team can respond to being behind in games (while allowing for the remaining time)</li>
                                        <li>How capable a team is of scoring outcome altering goals (especialy towards the end of the game)</li>
                                        <li>How susceptible a team is to conceding outcome altering scores (especialy towards the end of the game)</li>
                                    </ul></p>
                                <p>The core concepts in play in this type of analysis are <br />
                                    <ul>
                                            <li>KOIs - Key Outcome Indicators<br />
                                                These are based on events in the current match that are an analagous with those from previous fixtures in which the team/player were involved.<br />
                                          They look to highlight the more likely outcomes that can arise in the remaining time and how often these have happened previously.<br />
                                                A example of this in soccer is revealing how often a team trailing by a single goal with 5 minutes of play has rescued something from the match in that remaining time.
                                      </li>
                                        <li>OAPs - Outcome Altering Phases<br />
                                        In sport these are phases of play which result in an immediate change in the overall outcome of a match at that point.<br />
                                        The simplest example of this is an equaliser in football.<br />
                                        This is an outcome altering goal because it immediately changes the status of a game to draw resulting in what would be a more favourable final position for one of the competitors.<br />
                                        An example of a non outcome altering score is in soccer where a team increases their lead from 2-0 to 3-0.

                                      </li>
                                    </ul></p>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    </Accordion>
                </div>
                <div class ="d1">    
                <h1 class="p11">
                    Other info and analytics also showcased via tweets to our followers on twitter <br />  <a href="https://twitter.com/sportoutcomes?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @sportoutcomes</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </h1>
                <br />
                
    <p class="p11">  <img class="img12" src="https://sportoutcomes.com/onewebmedia/2.png" /> English Premier League <img class="img12" src="https://sportoutcomes.com/onewebmedia/2.png" />  </p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6DFsautar40" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <p class="p11">  <a href="\fetch-data-soccer" ><span>In play - here</span> </a> </p>
                    <p class="p11"> <a href="\SoccerPlayers" ><span> Players - here</span> </a> </p>                  
                    <p class="p11"> <a href="\SoccerTeams" ><span> Teams - here</span> </a> </p>                 

                    <p class="p11"> <a href="https://sportoutcomes.com/ScoreAssistEPL.html">  Scorer with Assist records</a> </p>
    <p class="p11"> <a href="https://sportoutcomes.com/TCC-F.html">  Strikers - Rankings</a> </p>
    <p class="p11"> <a href="https://sportoutcomes.com/TCC-M.html">  Midfielders - Rankings</a> </p>
      <p class="p11"> <a href="https://sportoutcomes.com/TCC-D.html">  Defenders - Rankings</a> </p>
    <p  class="p11"> <a href="https://sportoutcomes.com/TCC-G.html">  Goalkeepers - Rankings</a> </p>
    <br /> 
      
     <br />
     
     <br />
      <p class="p11">  <img class="img12" src="https://sportoutcomes.com/onewebmedia/racing.png" /> Cheltenham Festival <img class="img12" src="https://sportoutcomes.com/onewebmedia/racing.png" /></p>
    <h1>  <img class ="img5" src="https://sportoutcomes.com/onewebmedia/horseracenew.png" /> </h1>
    <p class="p11">   <a href="https://sportoutcomes.com/Chelt-1.html"> Previous Form</a> </p>
     <p class="p11">  <a href="https://sportoutcomes.com/Chelt-PreviousRuns.html"> Winners with previous festival runs</a> </p>
    <p class="p11"> <a href="https://sportoutcomes.com/Chelt-Trainers.html"> Trainer Record</a> </p>
    <p class="p11"> <a href="https://sportoutcomes.com/Chelt-Jockeys.html"> Jockey Record</a> </p>
     
    <p class="p11"> <a href="https://sportoutcomes.com/Sport Outcomes Cheltenham Festival P&L.xlsx" download>
        
        Download the Festival P&L assistant
 
</a> </p>

 <p class="p11"> <a href="https://docs.google.com/spreadsheets/d/1FxxAptjy-m_wZYlZZazx5Q-EnRl10huYDsCb_DImc9A/edit?usp=sharing"> Festival P&L assistant - Google Sheet</a> </p>

<br />


      <p class="p11">  <img class="img12" src="https://sportoutcomes.com/onewebmedia/racing.png" /> Aintree Festival <img class="img12" src="https://sportoutcomes.com/onewebmedia/racing.png" /></p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/L6FvT4RFK7M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <p class="p11">   <a href="https://sportoutcomes.com/AintreeCheltS1.html"> Previous Winners - Analytics</a> </p>
     <p class="p11">  <a href="https://sportoutcomes.com/AintreeCheltS2.html"> Form from Cheltenham - Summary</a> </p>
     <p class="p11">   <a href="https://sportoutcomes.com/AintreeDay1.html"> All Runners - Key Form pointers</a> </p>
    <p class="p11"> <a href="https://sportoutcomes.com/Chelt-AintreeQ.html"> Record of Cheltenham winners</a> </p>
    <p class="p11"> <a href="https://sportoutcomes.com/Aintree-CheltQ.html"> Winners with Cheltenham runs</a> </p>
     <p class="p11">  <a href="https://sportoutcomes.com/Aintree-PreviousRuns.html"> Winners with previous festival runs</a> </p>
     <p class="p11"> <a href="https://sportoutcomes.com/Aintree-Trainers.html"> Trainer Record</a> </p>
    <p class="p11"> <a href="https://sportoutcomes.com/Aintree-Jockeys.html"> Jockey Record</a> </p>
     <br />
     <p class="p11"> <img class="img12" src="https://sportoutcomes.com/onewebmedia/snookericon.png" /> Snooker <img class="img12" src="https://sportoutcomes.com/onewebmedia/snookericon.png" /> </p>
     <iframe width="560" height="315" src="https://www.youtube.com/embed/ZgjUFESsi3c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <p class="p11"> <a href="https://www.sportoutcomes.com/snooker.html">  World Championships 2022</a> </p>
       <p class="p11"> <a href="https://www.sportoutcomes.com/SnookerQualies.html">  World Qualifiers</a> </p>
       <p class="p11"> <a href="https://www.sportoutcomes.com/JudgementDay.html">  Judgement Day 2023</a> </p>
     <br/>
     <p class="p11">  <img class="img12" src="https://sportoutcomes.com/onewebmedia/tenniscon.png" /> Tennis <img class="img12" src="https://sportoutcomes.com/onewebmedia/tenniscon.png" /></p>
      <p class="p11"> <a href="https://www.sportoutcomes.com/tennis.html"> Grand Slams</a> </p>
     <iframe width="560" height="315" src="https://www.youtube.com/embed/g5UwEh9Esf0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
       <br />
         <br />
     <p class="p11"> <img class="img12" src="https://sportoutcomes.com/onewebmedia/golficon.png" /> Golf <img class="img12" src="https://sportoutcomes.com/onewebmedia/golficon.png" /> </p>
     <iframe width="560" height="315" src="https://www.youtube.com/embed/hl524VQyVAY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <p class="p11"> <a href="https://www.sportoutcomes.com/USMastersS.html">  US Masters 2023 Pedigrees</a> </p>
       <p class="p11"> <a href="https://www.sportoutcomes.com/USMasters.html">  US Masters 2023 Pedigrees+</a> </p>
                    <br />
                   
     
               

                <br/>
                <p class="p11">Our Sport Outcomes showcase will introduce you to some new concepts and ways of thinking about the dynamics of a sporting event. It is where we introduce Key Outcome Indicators (KOI's) and Outcome Altering Phases of Plays (OAPoP's) and showcase how these are often(but not always) carried forward into the current fixture. The underlying analytics are compiled
            from historical data for all of the competitors contesting the fixture with appropriate and relevant sample size history.</p>
                <p class="p11">Our users use this service for a wide range of use-cases from sports betting signals, player and term performance analysis, outcome predictions and live sports intelligence.</p>
                    <p class="p11">We cover Soccer (football), Basketball and Hockey in many regions such as UK, America and Europe.</p>


                    <div class="d1"><a href="https://sportoutcomes.com" target="_blank"><span>Visit SportOutcomes.Com</span> </a></div>

                </div>

              
             
            </div>
        );
    }
}
