import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,  } from 'reactstrap';
import { NavDropdown} from "react-bootstrap";
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
      super(props);
      var today = new Date().toUTCString();
      today = today.split(' ').slice(0, 4).join(' ');
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true, dateshow: today
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
        <header class="h1">   
           
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <div class = "p9"> {this.state.dateshow}     </div>   
          <Container>
                    <NavbarBrand tag={Link} to="/"><img class="img4" src="images/sportoutcomeslogonewbw2.png" /> Sport Outcomes </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
             <ul className="navbar-nav flex-grow">
                          
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem> 
                {/*<NavItem>*/}
                {/*    <NavLink tag={Link} className="text-dark" to="/LiveKOIs">Live KOIs</NavLink>*/}
                {/*</NavItem>*/}
                <NavDropdown title="Soccer" id="basic-nav-dropdown">
                   <NavDropdown.Item href="/SoccerTeams">Teams</NavDropdown.Item>
                   <NavDropdown.Item href="/SoccerPlayers">Players</NavDropdown.Item>
                    <NavDropdown.Item href="/fetch-data-soccer"> In Play</NavDropdown.Item>
                </NavDropdown>                
                {/*<NavDropdown title="Hockey" id="basic-nav-dropdown">*/}
                {/*   <NavDropdown.Item href="/HockeyTeams">Teams</NavDropdown.Item>*/}
                {/*  <NavDropdown.Item href="/HockeyH2H">Head2Heads</NavDropdown.Item>*/}
                {/*    <NavDropdown.Item href="/fetch-data-hockey"> In Play</NavDropdown.Item>*/}
                {/*</NavDropdown>*/}
                {/*<NavDropdown title="Basketball" id="basic-nav-dropdown">*/}
                {/*    <NavDropdown.Item href="/BasketballTeams">Teams</NavDropdown.Item>*/}
                {/*    <NavDropdown.Item href="/BasketballH2H">Head2Heads</NavDropdown.Item>*/}
                {/*    <NavDropdown.Item href="/fetch-data-basketball"> In Play</NavDropdown.Item>*/}
                {/*</NavDropdown>  */}
              </ul>
            </Collapse>
          </Container>
            </Navbar>           
      </header>
    );
  }
}
