import React, { Component } from 'react';
import moment from 'moment';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);

//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'       
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

export class LiveKOIs extends Component {
    static displayName = LiveKOIs.name;

  constructor(props) {
    super(props);
    this.state = { liveKOIs: [], loading: true };
    }
   
    intervalID;

  componentDidMount() {
      this.populateKOIs();      
     this.intervalID = setInterval(this.populateKOIs.bind(this), 2000);
    }
    componentWillUnmount() {       
        clearInterval(this.intervalID);
    }

    static renderliveKOIsTable(liveKOIs) {
       
      return (
          <div class="tb">  
              <div class ="p7">  
              <i class="fas fa-futbol" title="football"></i>
              <img src="images/gb-eng.svg" alt="England" width="38" height="20" />
              <img src="images/it.svg" alt="Italy" width="38" height="20" />
              <img src="images/es.svg" alt="Spain" width="38" height="20" />
              <img src="images/de.svg" alt="Germany" width="38" height="20" />
              <img src="images/fr.svg" alt="France" width="38" height="20" />
              <img src="images/nl.svg" alt="Netherlands" width="38" height="20" />
              <img src="images/pt.svg" alt="Portugal" width="38" height="20" />
              <img src="images/be.svg" alt="Belgium" width="38" height="20" />
              <img src="images/ar.svg" alt="Argentina" width="38" height="20" />
            &nbsp;<i class="fas fa-basketball-ball" title="basketball"></i>
              <img src="images/us.svg" alt="North America" width="38" height="20" />
              <img src="images/eu.svg" alt="Europe" width="38" height="20" />
           &nbsp; <i class="fas fa-hockey-puck" title="hockey"></i>
              <img src="images/us.svg" alt="North America" width="38" height="20" />
              <img src="images/eu.svg" alt="Europe" width="38" height="20" />
              </div>
              <br/>
             
              <p class="p7">                
                  <i class="fas fa-futbol"></i><a href="\fetch-data-soccer" ><span> In play</span> </a>
                  <i class="fas fa-futbol"></i><a href="\SoccerPlayers" ><span> Players</span> </a>
                  <i class="fas fa-futbol"></i>  <a href="\SoccerTeams" ><span>Teams</span> </a><i class="fas fa-futbol"></i>
              </p>             
              <p class="p7">              
                  <i class="fas fa-basketball-ball"></i><a href="\fetch-data-basketball" ><span> In play</span> </a>
                  <i class="fas fa-basketball-ball"></i><a href="\BasketballH2H" ><span> Head2Heads</span> </a>
                  <i class="fas fa-basketball-ball"></i>  <a href="\BasketballTeams" ><span>Teams</span> </a><i class="fas fa-basketball-ball"></i>
              </p>             
              <p class="p7">                
                  <i class="fas fa-hockey-puck"></i><a href="\fetch-data-hockey" ><span> In play</span> </a>
                  <i class="fas fa-hockey-puck"></i><a href="\HockeyH2H" ><span> Head2Heads</span> </a>
                  <i class="fas fa-hockey-puck"></i>  <a href="\HockeyTeams" ><span>Teams</span> </a><i class="fas fa-hockey-puck"></i>
              </p>
          <table>
         <thead>
          <tr>
          <th></th>
          <th></th>         
          </tr>
        </thead>
        <tbody>
          {liveKOIs.map(LiveKOI =>
              <tr key={LiveKOI.matchId}>
                  <td class="KOI"> <i class={this.getsporticon(LiveKOI.sportId)}></i><img src={this.getimageflag(LiveKOI.countryCode)} />&nbsp;{moment(LiveKOI.dateInserted).format('MMMM Do YYYY, H:mm')}</td>
                  <td class="KOI">{LiveKOI.koi.toString().replace(/;/g, '\n').replace('amp\n', '')}</td>             
            </tr>
          )}
        </tbody>
              </table>
       </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p></p>
        : LiveKOIs.renderliveKOIsTable(this.state.liveKOIs);

    return (
        <div>  
            <h id="tabelLabel" >KOIs are served up in real time here when games are in play </h>  
            <p class="p7"> Also tweeted to followers on twitter - <a href="https://twitter.com/sportoutcomes?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @sportoutcomes</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></p>
            <p> <br /> </p>
        {contents}
      </div>
    );
  }

  async populateKOIs() {
      const response = await fetch('https://ipsoa-api.azurewebsites.net/api/LiveKOIs?');
    const data = await response.json();
    this.setState({ liveKOIs: data, loading: false });
    } 

    static getleagueicon(competitionid) {
        var imgtag;
        imgtag = "images/" + competitionid + ".png";
        return imgtag;
    }

    static getimageflag(countrycode) {
        var imgtag;
        countrycode = countrycode.toLowerCase();
        imgtag = "images/" + countrycode + ".svg";
        return imgtag;
    }

    static getsporticon(sportid) {
        var imgtag;
        switch (sportid) {
            case 1:
                imgtag = "fas fa-futbol";
                break;
            case 2:
                imgtag = "fas fa-hockey-puck";
                break;
            default:
                imgtag = "fas fa-basketball-ball";
        }       
        return imgtag;
    }

   

}
