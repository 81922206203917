import React, { Component } from 'react';
import moment from 'moment';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);

//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'       
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

export class BasketballTodayHH extends Component {
    static displayName = BasketballTodayHH.name;

  constructor(props) {
    super(props);
    this.state = { liveKOIs: [], loading: true };
    }
   
    intervalID;

  componentDidMount() {
      this.populateHeadToHeads();     
     
    }
    componentWillUnmount() {       
        clearInterval(this.intervalID);
    }

    static renderHeadToHeadsTable(HeadToHeads) {

        var lines = [];       
        var Summary = [];
        var fixtures = [];
        var match;   
        var scorecolor1 = "white"; 
        var scorecolor2 = "white"; 
        var scorecolorPFH = "white";
        var scorecolorPFA = "white"; 
        var scorecolorPFH = "white";
        var scorecolorPFA = "white"; 
        var scorecolorOAG1 = "white";
        var scorecolorOAG2 = "white";
        var firstGoalColor = "white";       
        var homeawayColor = "white";
        var homeTeam = "";
        lines.push(<tr bgcolor ="burlywood"><td>  Fixture Date</td>
            <td>Home</td>
            <td></td>
            <td>Away</td>           
            <td>First 8 point lead</td>
            <td>Score 1 </td>
            <td>Score 2</td>
            <td>Q4 Score 1</td>
            <td>Q4 Score 2</td>
           
        </tr>)
        for (var i = 0; i < HeadToHeads.length; i++)        {
        
           
            match = HeadToHeads[i]; 

            if (homeTeam != match["home"]) {
                lines.push(<tr><td height="10px" bgcolor="white" colspan="11"></td></tr>)
                homeTeam = match["home"];
            }
            scorecolor1 = "white";
            scorecolor2 = "white";
            scorecolorPFH = "white";
            scorecolorPFA = "white"; 
            scorecolorOAG1 = "white";
            scorecolorOAG2 = "white";
            firstGoalColor = "white";
            homeawayColor = "Gainsboro";
            if (match["score1"] > match["score2"]) { scorecolor1 = "Moccasin" } else { scorecolor2 = "Gainsboro" }
            if (match["scorePFH"] > match["scorePFA"]) { scorecolorPFH = "Moccasin" } else { scorecolorPFA = "Gainsboro" }
            if (match["scoreOAG1"] > match["scoreOAG2"]) { scorecolorOAG1 = "Moccasin" } else { scorecolorOAG2 = "Gainsboro" }
            if (match["home"] == match["goal1"]) { firstGoalColor = "Moccasin" } else { firstGoalColor = "Gainsboro" }
            if (match["homeAway"] == "home") { homeawayColor = "Moccasin" }
            if (match["homeAway"] == "away") { homeawayColor = "Gainsboro" }
            lines.push(<tr><td bgcolor="burlywood">  {moment(match["fixtureDate"]).format('MMMM Do YYYY')}</td>
                <td bgcolor="Moccasin">{match["home"]}</td>
                <td bgcolor={homeawayColor}>Home</td>
                <td bgcolor="Gainsboro">{match["away"]}</td> 
                <td bgcolor={firstGoalColor}>{match["goal1"]}</td>
                <td bgcolor={scorecolor1 }>{match["score1"]}</td>
                <td bgcolor={scorecolor2}>{match["score2"]}</td>
                <td bgcolor={scorecolorPFH}>{match["scorePFH"]}</td>
                <td bgcolor={scorecolorPFA}>{match["scorePFA"]}</td>
               
            </tr>)
        }
       
      return (
          <div class="tb">   
              <div class="p7"> Score 1 = Points scored by todays home team in that previous meeting</div>
              <div class="p7"> Score 2 = Points scored by todays visting team in that previous meeting</div>
              <div class="p7"> Q4 = Quarter 4</div>
              <p class="p7">
                  <i class="fas fa-basketball-ball"></i>  <a href="\LiveKOIs" ><span>Live feed - here</span> </a><i class="fas fa-basketball-ball"></i><br />
                  <i class="fas fa-basketball-ball"></i> <a href="\fetch-data-basketball" ><span> In play - here</span> </a><i class="fas fa-basketball-ball"></i><br />
                  <i class="fas fa-basketball-ball"></i><a href="\BasketballTeams" ><span> Teams - here</span> </a><i class="fas fa-basketball-ball"></i>
              </p>
          <table class ="t5">
         <thead>
          <tr>
          <th></th>
          <th></th>         
          </tr>
        </thead>
         {lines}
         </table>
             
              
       </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p></p>
        : BasketballTodayHH.renderHeadToHeadsTable(this.state.HeadToHeads);

    return (
        <div>
			<div class="icon-container">  
            <i class="fas fa-basketball-ball" title="basketball"></i>
            <img src="images/us.svg" alt="North America" width="38" height="20" />
            <img src="images/eu.svg" alt="Europe" width="38" height="20" />
			</div>
           
            <br></br>
        <h id="tabelLabel" >Head to Heads for all todays major basketball league match ups</h>       
            {contents}
            <div class="p7"> <img class="img3" src="images/ts-logo.png" alt="Tallysight" /><a href="https://tallysight.com/membership/andrew-dineen" > Expert opinions/views/predictions which are sourced from here can be accessed on Tallysight </a></div>
      </div>
    );
  }

  async populateHeadToHeads() {
      const response = await fetch('https://ipsoa-api.azurewebsites.net/api/TodaysHeadToHeads?sport=3');
    const data = await response.json();
    this.setState({ HeadToHeads: data, loading: false });
    } 

    static getleagueicon(competitionid) {
        var imgtag;
        imgtag = "images/" + competitionid + ".png";
        return imgtag;
    }

    static getimageflag(countrycode) {
        var imgtag;
        countrycode = countrycode.toLowerCase();
        imgtag = "images/" + countrycode + ".svg";
        return imgtag;
    }

    static getsporticon(sportid) {
        var imgtag;
        switch (sportid) {
            case 1:
                imgtag = "fas fa-futbol";
                break;
            case 2:
                imgtag = "fas fa-hockey-puck";
                break;
            default:
                imgtag = "fas fa-basketball-ball";
        }       
        return imgtag;
    }

   

}
