import React, { Component } from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-196201756-1');
ReactGA.pageview(window.location.pathname + window.location.search);
//import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//const appInsights = new ApplicationInsights({
//    config: {
//        instrumentationKey: '30ee3b71-9562-47a1-aa29-ea1677b4c7af',
//        IngestionEndpoint: 'https://northeurope-0.in.applicationinsights.azure.com/'
//        /* ...Other Configuration Options... */
//    }
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

export class FetchDataHockey extends Component {
    static displayName = FetchDataHockey.name;

    constructor(props) {
        super(props);
        this.state = { scores: [], goalsData: [], loading: true, expanded: false, currentdateandtime: "", mobileclient: false, firstpopulate: true};
    }

    intervalID;
    componentDidMount() {
        this.state.goalsData = [];
        this.state.mobileclient = detectMobile();
        this.populateScoreData(this.state.goalsData, this.state.firstpopulate);
        // Need to make this run at a specified interval   
        //this.intervalID = setInterval(this.updateScores.bind(this), 30000);
        function detectMobile() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i];

            var bDevice = toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
            var bResolution = ((window.innerWidth <= 800) && (window.innerHeight <= 600))
            return (bDevice || bResolution);
        };
    }

    componentWillUnmount() {
        // stop getData() from continuing to run even  after unmounting this component        
        clearInterval(this.intervalID);
    }
    async updateScores() {
        this.populateScoreData(this.state.goalsData, this.state.firstpopulate);
    }
    static renderScoresTable(scores, goals, mobileclient) {
        var lines = [];       
        var Summary = [];
        var fixtures = [];
        var match;
        var matchsummary;
        var matchstats;
        var matchstatsline = "";
        var team1 = "";
        var team2 = "";
        var competitionid = -1;
        var competitionname = "";
        var competitionnamecheck = "";
        var countrycode = "";
        var imagetag = "";
        var imgicon = "";
        var statuscheck = "FT-OT-AOT-AP"
        var clientclass = "accordion__item_browser";
        if (mobileclient)
            clientclass = "accordion__item_mobile";

        for (var i = 1; i < scores.length; i++) {
            lines = [];
            Summary = [];           
            match = scores[i];
            matchsummary = match[0]["scoreline"];
            matchstats = match[0]["matchstats"];
            competitionid = match[0]["competitionid"];
            competitionname = match[0]["competitionname"];
            countrycode = match[0]["countrycode"];
            imagetag = this.getimageflag(countrycode);
            team1 = match[0]["Team1"];
            team2 = match[0]["Team2"];
            
            
            if (matchsummary.Score1 == '?' || statuscheck.indexOf(matchsummary.Status) > -1) {
                Summary.push(<div class="matchsummary"><div class="matchsummary"> {matchsummary.Team1}</div> <div class="goal"> {matchsummary.Score1} </div> <div class="matchsummary">{matchsummary.Team2} </div> <div class="goal">{matchsummary.Score2} </div><div class="matchsummary"> {matchsummary.Status} </div> </div>);
            } else {
                Summary.push(<div class="matchsummary"><div class="matchsummaryip"> {matchsummary.Team1}</div> <div class="goal"> {matchsummary.Score1} </div> <div class="matchsummaryip">{matchsummary.Team2} </div> <div class="goal">{matchsummary.Score2} </div><div class="matchsummary"> {matchsummary.Status} </div> </div>);
            }
            if (matchstats) {
                matchstatsline = this.renderStatsList(matchstats, goals, team1, team2, mobileclient);
                lines.push(<div> {matchstatsline} </div>);
            }

            if (competitionname != competitionnamecheck) {
                imgicon = this.getleagueicon(competitionid);
                fixtures.push(<div class="league"><img src={imagetag} />&nbsp;<img class="img3" src={imgicon} />&nbsp;{competitionname} </div>);
                competitionnamecheck = competitionname;
            }
            fixtures.push(
                <Accordion allowZeroExpanded="true">
                    <AccordionItem class={clientclass}>
                        <AccordionItemHeading >
                            <AccordionItemButton>
                                <i class="fas fa-hockey-puck"></i> {Summary}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {lines}
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>)
        }
        return (
            <div>
                {fixtures}
            </div>
        );
    }

    static renderStatsList(stats, goals, team1, team2, mobileclient) {
        var lines = [];
        var matchstatsarray = [];
        var matchstatsline;
        var goalscored = "";
        var team = "";
        var goalskey = ""
        var stylecolor;
        var styleparam;
        var clientclassaccordion = "accordion__item_browser";
        var clientclassgoals = "goals_browser";
        if (mobileclient) {
            clientclassaccordion = "accordion__item_mobile";
            clientclassgoals = "goals_mobile";
        }

        for (var j = 0; j < stats.length; j++) {
            matchstatsarray = stats[j];
            matchstatsline = matchstatsarray[0]["value"];
            styleparam = matchstatsarray[0]["style"]
            goalskey = matchstatsarray[0]["key"];
            stylecolor = this.getStatStyle(styleparam);
            if (styleparam == 1) { team = team1 } else { team = team2 }
            goalscored = this.GetTeamGoalsContext(goals, team, goalskey)
            lines.push(<Accordion allowZeroExpanded="true" >
                <AccordionItem class={clientclassaccordion}>
                    <AccordionItemHeading>
                        <AccordionItemButton style={stylecolor}>
                            {matchstatsline}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div class={clientclassgoals}> {goalscored}</div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion >);
        }
        return (
            <React.Fragment>
                {lines}
            </React.Fragment>)
    }
    static getStatStyle(action) {
        const liStyleGreen = { color: 'Green', height: '26px', font: 'Verdana' };
        const liStyleRed = { color: 'Red', height: '26px', font: 'Verdana' };
        const liStyledefault = { color: 'blue', height: '26px', font: 'Verdana' };
        var stylereturn;
        switch (action) {
            case 1:
                stylereturn = liStyledefault;
                break;
            case 2:
                stylereturn = liStyleRed;
                break;
            default:
                stylereturn = liStyledefault;
        }
        return stylereturn;
    }
    static getimageflag(countrycode) {
        var imgtag;
        countrycode = countrycode.toLowerCase();
        imgtag = "images/" + countrycode +".svg";       
        return imgtag;
    }
    static getleagueicon(competitionid) {
        var imgtag;
        imgtag = "images/" + competitionid +".png";  
        return imgtag;
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : FetchDataHockey.renderScoresTable(this.state.scores, this.state.goalsData, this.state.mobileclient, this.state.firstpopulate);
        return (<div>{this.state.currentdateandtime}
            <p class="p5"> Today's showcase Hockey action</p> 
           
            <p> Click on the arrowheads to expand the details of each match</p>
            <img class="img5" src="images/arrowhockey.png" alt="navigate" />
            <p> <br /> </p>
            <p> <b>NOTE: All analytics will update on a minute by minute basis once the games are in play</b></p>
            <p>Big inplay KOI's also tweeted to followers on twitter - <a href="https://twitter.com/sportoutcomes?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @sportoutcomes</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></p>
            <p> <br /> </p>
            <p class="p8">
                <i class="fas fa-hockey-puck"></i>  <a href="\LiveKOIs" ><span>Live feed - here</span> </a><i class="fas fa-hockey-puck"></i><br />
                <i class="fas fa-hockey-puck"></i><a href="\HockeyH2H" ><span> Head2Heads - here</span> </a><i class="fas fa-hockey-puck"></i><br />
                <i class="fas fa-hockey-puck"></i>  <a href="\HockeyTeams" ><span>Teams - here</span> </a><i class="fas fa-hockey-puck"></i></p>
            <p> <br /> </p>
            {contents}
            <p/>
            <p class="p3"> <a href="mailto:development@sportoutcomes.com">Report Issue</a></p>
            <p>The material and information provided on this site is for general information purposes only.<br /> You should not rely upon this material or information as a basis for making any business, legal or any other decisions.</p>
        </div>);
    }

    async populateScoreData(goals, firstcall) {

        try {

            let response = await fetch
                ('https://ipsoa-api.azurewebsites.net/api/TodaysFixtures?sport=2');
            if (!response.ok) {
                alert('Network response was not ok');
            }
            let jsonResponse = await response.json();
            let Matches = jsonResponse;
            var i;
            var j;
            j = 0;
            var scoredata = [];
            for (i = 0; i < Matches.length; i++) {
                var match = Matches[i];
                var competitionid = match.competitionId;
                var competitionname = match.competitionName;
                var countrycode = match.countryCode;
                var Team1 = match.home;
                var Team2 = match.away;
                var Team1Id = match.homeId;
                var Team2Id = match.awayId;
                var FixtureId = match.fixtureId;
                var Score1 = match.homeScore;
                var Score2 = match.awayScore;
                var Status = match.status;
                Status = await this.AdjustStatus(Status);
                var timer = match.gameTime;
                if (timer < 0) {timer = 0}               
                var scoreline = { Team1, Team2, Score1, Score2, Status };
                var matchstats;               
                if (!(firstcall) | i < 3) { matchstats = await this.getMatchStatsList(FixtureId, timer).then(); }
                var matchlist;
                matchlist = [{ Team1, Team2, scoreline, matchstats, competitionid, competitionname, countrycode }];
                if (!(firstcall) | i < 3) { goals = await this.getGoalsData(goals, Team1, Team2, Team1Id, Team2Id).then(); }
                j = j + 1;
                scoredata[j] = matchlist;

            }
            var cdate = new Date().toUTCString();
            cdate = cdate.substring(cdate.length - 12, cdate.length)
            firstcall = false;
            this.setState({ scores: scoredata, goalsData: goals, loading: false, currentdateandtime: cdate, firstpopulate: firstcall});

        }
        catch (err) {
            // catches errors both in fetch and response.json
            // alert(err.message);  throw this away for now as we cannot send popups to user here
            return 'trying';
        };
    }
    async  AdjustStatus(status) {
        // put out the correct time for the time zone of browser/mobile user
        if (status) {
            if (status.indexOf(":") == 2 || status.length == 5) {
                var cdate = new Date();
                var offset = cdate.getTimezoneOffset() / 60;
                var sdate = "";
                sdate = cdate.getFullYear() + '/' +
                    (cdate.getMonth() + 1) + '/' +
                    cdate.getDate() + ' ' + status
                cdate = new Date(sdate);
                cdate.setHours(cdate.getHours() - offset);
                status = (cdate.getHours() < 10 ? '0' : '') + cdate.getHours() + ':' + (cdate.getMinutes() < 10 ? '0' : '') + cdate.getMinutes();
            }
        }
        return status;
    }

    async getMatchStatsList(fixtureId, gameMinute) {
        try {
            let url = 'https://ipsoa-api.azurewebsites.net/api/KOIs?matchId=' + fixtureId + '&gameMinute=' + gameMinute + '&sport=2';
            let response = await fetch(url);
            let data = await response.json();
            var itemcounter
            var statlist = [];
            itemcounter = 0;
            if (data.fgH_action == '1') {
                statlist[itemcounter] = [{ key: "fgh", value: data.fgh, style: 1 }];
                itemcounter += 1;
            }
            if (data.asH_action == '1') {
                statlist[itemcounter] = [{ key: "ash", value: data.ash, style: 1 }];
                itemcounter += 1;
            }
            if (data.h2GH_action == '1') {
                statlist[itemcounter] = [{ key: "h2GH", value: data.h2GH, style: 1 }];
                itemcounter += 1;
            }
            if (data.fgcH_action == '1') {
                statlist[itemcounter] = [{ key: "fgch", value: data.fgch, style: 1 }];
                itemcounter += 1;
            }
            if (data.cbhE_action == '1') {
                statlist[itemcounter] = [{ key: "cbhe", value: data.cbhe, style: 1 }];
                itemcounter += 1;
            }
            if (data.b2GH_action == '1') {
                statlist[itemcounter] = [{ key: "b2GH", value: data.b2GH, style: 1 }];
                itemcounter += 1;
            }
            if (data.hdB_action == '1') {
                statlist[itemcounter] = [{ key: "hdb", value: data.hdb, style: 1 }];
                itemcounter += 1;
            }
            if (data.ilgH_action == '1') {
                statlist[itemcounter] = [{ key: "ilgh", value: data.ilgh, style: 1 }];
                itemcounter += 1;
            }
            if (data.eqhS_action == '1') {
                statlist[itemcounter] = [{ key: "eqhs", value: data.eqhs, style: 1 }];
                itemcounter += 1;
            }
            if (data.eqhC_action == '1') {
                statlist[itemcounter] = [{ key: "eqhc", value: data.eqhc, style: 1 }];
                itemcounter += 1;
            }
            if (data.fgA_action == '1') {
                statlist[itemcounter] = [{ key: "fga", value: data.fga, style: 2 }];
                itemcounter += 1;
            }
            if (data.asA_action == '1') {
                statlist[itemcounter] = [{ key: "asa", value: data.asa, style: 2 }];
                itemcounter += 1;
            }
            if (data.h2GA_action == '1') {
                statlist[itemcounter] = [{ key: "h2GA", value: data.h2GA, style: 2 }];
                itemcounter += 1;
            }
            if (data.fgcA_action == '1') {
                statlist[itemcounter] = [{ key: "fgca", value: data.fgca, style: 2 }];
                itemcounter += 1;
            }
            if (data.cbaE_action == '1') {
                statlist[itemcounter] = [{ key: "cbae", value: data.cbae, style: 2 }];
                itemcounter += 1;
            }
            if (data.b2GA_action == '1') {
                statlist[itemcounter] = [{ key: "b2GA", value: data.b2GA, style: 2 }];
                itemcounter += 1;
            }
            if (data.adB_action == '1') {
                statlist[itemcounter] = [{ key: "adb", value: data.adb, style: 2 }];
                itemcounter += 1;
            }
            if (data.ilgA_action == '1') {
                statlist[itemcounter] = [{ key: "ilga", value: data.ilga, style: 2 }];
                itemcounter += 1;
            }
            if (data.eqaS_action == '1') {
                statlist[itemcounter] = [{ key: "eqas", value: data.eqas, style: 2 }];
                itemcounter += 1;
            }
            if (data.eqaC_action == '1') {
                statlist[itemcounter] = [{ key: "eqac", value: data.eqac, style: 2 }];
                itemcounter += 1;
            }

            return statlist
        }
        catch (err) {
            // catches errors both in fetch and response.json
            //alert(err.message);
            return statlist;
        };
    }

    async getGoalsData(goalsdata, team1, team2, team1Id, team2Id) {
        try {
            var goalsscored = "";
            var newIndex = 0;
            var goalsExist = false;
            goalsExist = await this.TeamGoalsExist(goalsdata, team1).then();
            if (!goalsExist) {
                let url = 'https://ipsoa-api.azurewebsites.net/api/GoalsRaw?TeamId=' + team1Id + '&sport=2';               
                let response = await fetch(url);
                let data = await response.json();
                newIndex = goalsdata.length;
                goalsscored = await this.formatGoalsDataStruct(data);
                goalsdata[newIndex] = [{ Team: team1, goals: goalsscored }];
            }
            goalsExist = await this.TeamGoalsExist(goalsdata, team2).then();
            if (!goalsExist) {
                let url = 'https://ipsoa-api.azurewebsites.net/api/GoalsRaw?TeamId=' + team2Id + '&sport=2';             
                let response = await fetch(url);
                let data = await response.json();
                newIndex = goalsdata.length;
                goalsscored = await this.formatGoalsDataStruct(data);
                goalsdata[newIndex] = [{ Team: team2, goals: goalsscored }];
            }
            return goalsdata
        }
        catch (err) {
            // catches errors both in fetch and response.json
            // alert(err.message);  throw this away for now as we cannot send popups to user here
            return goalsdata;
        };
    }
    async formatGoalsData(data) {
        var returnstring = "";
        var goalsstring = "";
        var Goals1 = data.firstGoalsScored;
        var Goals2 = data.firstGoalsConceded;
        var Goals3 = data.equalisersScored;
        var Goals4 = data.equalisersConceded;
        var Goals5 = data.outcomeChangingGoalsScored;
        var Goals6 = data.outcomeChangingGoalsConceded;
        var Goals7 = data.twoGoalLeads;
        var Goals8 = data.twoGoalDeficits;
        goalsstring = "First Goals Scored;" + Goals1 + "\n First Goals Conceded;" + Goals2 + "\n Equalisers Scored;" + Goals3 + "\n Equalisers Conceded;" + Goals4 + "\n Outcome Changing Scored;" + Goals5 + "\n Outcome Changing Conceded;" + Goals6 + "\n Two goal leads;" + Goals7 + "\n Two goal deficits;" + Goals8;
        returnstring = goalsstring.toString().replace(/;/g, '\n');
        return returnstring;
    }
    async formatGoalsDataStruct(data) {
        var goalsdata = [];
        var Goals1 = "First Goals Scored\n" + data.firstGoalsScored.toString().replace(/;/g, '\n');

        var Goals2 = "First Goals Conceded \n" + data.firstGoalsConceded.toString().replace(/;/g, '\n');
        var Goals3 = "Equalisers Scored \n" + data.equalisersScored.toString().replace(/;/g, '\n');
        var Goals4 = "Equalisers Conceded \n" + data.equalisersConceded.toString().replace(/;/g, '\n');
        var Goals5 = "Outcome Changing Goals Scored \n" + data.outcomeChangingGoalsScored.toString().replace(/;/g, '\n');
        var Goals6 = "Outcome Changing Goals Conceded \n" + data.outcomeChangingGoalsConceded.toString().replace(/;/g, '\n');
        var Goals7 = "Two goal leads \n" + data.twoGoalLeads.toString().replace(/;/g, '\n');
        var Goals8 = "Two goal deficits \n" + data.twoGoalDeficits.toString().replace(/;/g, '\n');

        goalsdata[0] = [{ key: "fgs", value: Goals1 }];
        goalsdata[1] = [{ key: "fgc", value: Goals2 }];
        goalsdata[2] = [{ key: "eqs", value: Goals3 }];
        goalsdata[3] = [{ key: "eqc", value: Goals4 }];
        goalsdata[4] = [{ key: "igs", value: Goals5 }];
        goalsdata[5] = [{ key: "igc", value: Goals6 }];
        goalsdata[6] = [{ key: "tgl", value: Goals7 }];
        goalsdata[7] = [{ key: "tgd", value: Goals8 }];
        return goalsdata;
    }
    async TeamGoalsExist(goals, team) {
        var found = false;
        var teamcheck = "";
        try {
            for (var i = 0; i < goals.length; i++) {
                teamcheck = goals[i][0]["Team"];
                if (teamcheck == team) {
                    found = true;
                    break;
                }
            }
        }
        catch (err) {
            // alert(err.message);  throw this away for now as we cannot send popups to user here
        };
        return found
    }
    static GetTeamGoals(goals, team) {
        var teamcheck = "";
        var teamgoals = "";
        try {
            for (var i = 0; i < goals.length; i++) {
                teamcheck = goals[i][0]["Team"];
                if (teamcheck == team) {
                    teamgoals = goals[i][0]["goals"];
                    break;
                }
            }
        }
        catch (err) {
            // alert(err.message);  throw this away for now as we cannot send popups to user here
        };
        return teamgoals
    }
    static GetTeamGoalsContext(goals, team, key) {
        var teamcheck = "";
        var keycheck = "";
        var teamgoals = "";
        var goalskey = "";
        var goalscheck = [];
        try {

            switch (key) {
                case "fgh":
                case "fga":
                    goalskey = "fgs"
                    break;
                case "fgch":
                case "fgca":
                    goalskey = "fgc"
                    break;
                case "ash":
                case "asa":
                    goalskey = "eqc"
                    break;
                case "eqhc":
                case "eqac":
                    goalskey = "eqc:tgl"
                    break;
                case "cbae":
                case "cbhe":
                    goalskey = "eqs"
                    break;
                case "eqhs":
                case "eqas":
                    goalskey = "eqs:tgd"
                    break;
                case "ilgh":
                case "ilga":
                    goalskey = "igs:igc"
                    break;
                case "h2GH":
                case "h2GA":
                    goalskey = "tgl"
                    break;
                case "b2GH":
                case "b2GA":
                    goalskey = "tgd"
                    break;
                case "adb":
                case "hdb":
                    goalskey = "fgs:fgc"
                    break;
                default:
            }
            if (goalskey != "") {
                for (var i = 0; i < goals.length; i++) {
                    teamcheck = goals[i][0]["Team"];
                    if (teamcheck == team) {
                        goalscheck = goals[i][0]["goals"];
                        for (var j = 0; j < goalscheck.length; j++) {
                            var keycheck = goalscheck[j][0]["key"];
                            if (goalskey.indexOf(keycheck) > -1) {
                                if (teamgoals != '') { teamgoals = teamgoals + ' \n\n' }
                                teamgoals = teamgoals + goalscheck[j][0]["value"];
                            }
                        }
                    }
                }
            }
        }
        catch (err) {
            // alert(err.message);  throw this away for now as we cannot send popups to user here
        };
        return teamgoals
    }


}
